import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import PackageManager from '~/components/PackageManager';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader">
  Designsystemet gjør det enklere for deg som utvikler å lage gode, universelt
  utformede brukeropplevelser som er like på tvers av appene våre. Denne guiden
  viser deg hvordan du kan starte å bruke det!
    </PageHeader>
    <h2 {...{
      "id": "installer-pakker"
    }}>{`Installer pakker`}</h2>
    <p>{`Designsystemet er delt opp i et knippe forskjellige pakker, som lastes ned enkelthvis fra NPM. Du kan se en komplett oversikt over tilgjengelige pakker `}<a parentName="p" {...{
        "href": "http://npmjs.org/org/entur"
      }}>{`her`}</a>{`.`}</p>
    <p>{`Du kan bruke `}<inlineCode parentName="p">{`yarn`}</inlineCode>{` eller `}<inlineCode parentName="p">{`npm`}</inlineCode>{` til å innstallere pakkene - det er opp til prosjektet ditt. Har prosjektet ditt en `}<inlineCode parentName="p">{`yarn.lock`}</inlineCode>{`, bruker du `}<inlineCode parentName="p">{`yarn add`}</inlineCode>{`, og har du `}<inlineCode parentName="p">{`package-lock.json`}</inlineCode>{`, så bruker du `}<inlineCode parentName="p">{`npm`}</inlineCode>{`.`}</p>
    <p>{`Velg gjerne det du bruker i prosjektet ditt i innstillingsmenyen oppe i høyre hjørne.`}</p>
    <h3 {...{
      "id": "hvilke-pakker-trenger-jeg"
    }}>{`Hvilke pakker trenger jeg?`}</h3>
    <p>{`Det avhenger av hva du skal lage, men en god start kan være disse:`}</p>
    <PackageManager mdxType="PackageManager"></PackageManager>
    <h3 {...{
      "id": "hvorfor-ikke-bare-én-pakke"
    }}>{`Hvorfor ikke bare én pakke?`}</h3>
    <p>{`Når man har én pakke, har man også bare én versjon å forholde seg til - og det kan skape noen utfordringer etterhvert som designsystemet modnes.
Ved å dele opp i flere pakker med uavhengige versjonering, kan man få oppgradert en bugfiks på knappene sine, uten at man trenger å oppgradere andre eventuelle breaking changes som har skjedd siden sist du oppdaterte avhengigheten din.`}</p>
    <p>{`En annen fordel er at man ikke inkluderer mer kode enn man trenger, som kan ha en positiv effekt på mengden JavaScript og CSS ved å kun ha de komponentenene og den stylingen man behøver.`}</p>
    <p>{`Det er litt mer arbeid å komme i gang med nye prosjekter, men vedlikeholdet av applikasjonene blir enklere.`}</p>
    <h2 {...{
      "id": "inkluder-css"
    }}>{`Inkluder CSS`}</h2>
    <p>{`Du kan inkludere CSSen i hver pakke i prosjektet ditt på den måten som passer best for deg.`}</p>
    <p>{`Du kan importere CSS-filen i en `}<inlineCode parentName="p">{`.scss`}</inlineCode>{` eller `}<inlineCode parentName="p">{`.less`}</inlineCode>{` fil med et `}<inlineCode parentName="p">{`@import`}</inlineCode>{` statement:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import '~@entur/button/dist/styles.css';
`}</code></pre>
    <p>{`Om du bruker `}<inlineCode parentName="p">{`webpack`}</inlineCode>{`, kan du bruke en loader (`}<a parentName="p" {...{
        "href": "https://medium.com/a-beginners-guide-for-webpack-2/webpack-loaders-css-and-sass-2cc0079b5b3a"
      }}>{`her er en fin artikkel`}</a>{` som guider deg gjennom dette) til å importere det rett i JavaScripten din.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import '@entur/button/dist/styles.css';
`}</code></pre>
    <h2 {...{
      "id": "react-komponenter"
    }}>{`React-komponenter`}</h2>
    <p>{`Hver av pakkene kommer med sine egne universelt utformede React-komponenter, som gjør det enkelt for React-utviklere å lage komplette apper. `}<a parentName="p" {...{
        "href": "/komponenter"
      }}>{`'Du finner API-dokumentasjonen for alle komponentene her'`}</a>{`.`}</p>
    <h2 {...{
      "id": "typer"
    }}>{`Typer`}</h2>
    <p>{`Per i dag leveres alle pakker med komplette TypeScript-typings.`}</p>
    <p>{`Typene for alle komponentene er eksportert på toppnivå, og kan importeres for å brukes videre i egen kode.`}</p>
    <p>{`De er på formen `}<inlineCode parentName="p">{`KomponentnavnProps`}</inlineCode>{`, så eksempelvis så har komponenten `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{` typene eksponert via `}<inlineCode parentName="p">{`DropdownProps`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "exports"
    }}>{`Exports`}</h2>
    <p>{`Designsystemets pakker benytter seg kun av `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/web/javascript/reference/statements/export"
      }}>{`named exports`}</a>{`. Dette gjør at du alltid vil importere komponenter og funksjoner slik:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { EnKomponent, useEnHook } from '@entur/en-pakke';
`}</code></pre>
    <p>{`Du vil med andre ord `}<em parentName="p">{`aldri`}</em>{` se default exports.`}</p>
    <h2 {...{
      "id": "spørsmål"
    }}>{`Spørsmål?`}</h2>
    <p>{`Hvis du har spørsmål, ta kontakt på #talk-designsystem på Slack. Hvis du mangler tilgang til dokumentasjon (skisser og collections) i Figma, kontakt `}<a parentName="p" {...{
        "href": "mailto:sergio.haisch.timm@entur.org"
      }}>{`Sergio Haisch`}</a>{`.`}</p>
    <h2 {...{
      "id": "kom-i-gang-med-å-bidra"
    }}>{`Kom i gang med å bidra!`}</h2>
    <p>{`Designsystem-teamet trenger så mye hjelp de kan få - både med å skrive dokumentasjon, lage eksempler, skrive tester, finne bugs og fikse dem.`}</p>
    <p>{`Om du har tid til å gjøre det selv, kan du `}<a parentName="p" {...{
        "href": "https://github.com/entur/design-system"
      }}>{`lage et pull request`}</a>{`. Om du ikke har tid, kan du lage et `}<a parentName="p" {...{
        "href": "https://enturas.atlassian.com"
      }}>{`issue på Jira`}</a>{`. Om du bare lurer på noe, ta kontakt på #talk-designsystem på Slack.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      